

import Splide from '@splidejs/splide';


export default class {
	constructor(el, device) {
		this.DOM = {
			el: el,
			buttons: el.querySelectorAll('.pag_el'),
			tests: el.querySelectorAll('.cnt_info_el'),
			hold: el.querySelector('.cnt_info .hold')
		}
		this.device = device
		this.active = 0

		this.toRight = 0
		this.toLeft = 0

		this.create()
	}

	async create() {
		
		this.DOM.tests[this.active].classList.add('act')
		if(this.DOM.tests.length > 1){
			this.DOM.buttons[this.active].classList.add('act')
		}
		
		
	}

	async start() {
		
		
	}

	timeout(ms){
    return new Promise(resolve => setTimeout(resolve, ms))
  }

	initEvents() {
		if(this.DOM.buttons){
			for(let [i, a] of this.DOM.buttons.entries()){
				a.onclick = (e) =>{
					gsap.set(this.DOM.hold, { height: this.DOM.tests[this.active].clientHeight})
					this.DOM.tests[this.active].classList.remove('act')
					this.DOM.buttons[this.active].classList.remove('act')
					this.active = i
					gsap.to(this.DOM.hold, { height: this.DOM.tests[this.active].clientHeight, duration: .3, delay: .3, ease: 'Power2: inOut',
						onComplete: () =>{
							this.DOM.tests[this.active].classList.add('act')
							this.DOM.buttons[this.active].classList.add('act')

							gsap.set(this.DOM.hold, { height: 'auto'})
						}
					})
					
				}
			}	
		}

	}

	removeEvents() {}

	onResize() {
		
	}


}
