import Page from '/js🧠🧠🧠/page👁️/pagemain.js'

//COMPS
import Intro from './0Intro'
import Form from '/components🦾🦾🦾/Contacto'


class Home extends Page {
  constructor (main) {
    super(main)
  }

  async create(content,main,temp=undefined) {
    super.create(content,main)
    if(temp!=undefined){

      document.querySelector('#content').insertAdjacentHTML('afterbegin',temp)
    }
    else{
      let data = await this.loadRestApi(this.main.base+'/wp-json/wp/v2/pages/',content.dataset.id,content.dataset.template)
      document.querySelector('#content').insertAdjacentHTML('afterbegin',data.csskfields.main)
    }
    
  
    this.el = document.querySelector('main')
    

    this.DOM = {
      el:this.el
    }

    gsap.set(this.DOM.el, {opacity: 0})

    await this.loadImages()
    await this.loadVideos()
    


    await this.createComps()
    await this.createIos()
    

    await this.getReady()
  }
  iOpage(animobj){
    // if (animobj.el.classList.contains('iO-scrollservices')) {
		// 	animobj.class = new Scrollservices(animobj, this.main)
		// }
    return animobj
  }

  
  
  async createComps(){

    if (this.DOM.el.querySelector('.pizarra_main')) {
			this.components.intro = new Intro(
				this.DOM.el.querySelector('.pizarra_main'),
				this.main,
        this.main.isTouch
			)
		}

    if(this.DOM.el.querySelector('.Cform')){
      this.components.forms = []
      for(let a of this.DOM.el.querySelectorAll('.Cform')){
        this.components.forms.push(new Form(a, this.main))
      }
    }
    await super.createComps()
   

  }


  async animIntro(val){
    gsap.to(this.DOM.el, { opacity: 1, duration: .9, ease: 'Power2: inOut'})

    return val
   
  }

  async animOut(){
    await gsap.to(this.DOM.el, { opacity: 0, duration: .6, ease: 'Power2: inOut'})
    
  }

}




export default Home