
export default class {
  constructor (el,main, touch) {


    this.DOM = {
      el:el,
      canvas: el.querySelector('canvas'),
      colors: el.querySelectorAll('.tools .colors button'),
      brush: el.querySelector('.tools .brush'),
      range: el.querySelector('.tools .brush input'),
      erase: el.querySelector('.tools .erase button'),
    }
    this.main = main
    this.touch = touch
    this.active = 0
    this.drawLine = 4
    this.drawColor = "#FFFAF5"
    
    
    this.create()

  }

  async create(){
    
  
  }
  
  async start(){

  }


  drawline(x1,y1,x2,y2,color,line) {
    this.ctx.strokeStyle = color;
    this.ctx.lineJoin = "round";
    this.ctx.lineCap = "round";
    this.ctx.lineWidth = line;

    this.ctx.shadowOffsetX = 0
    this.ctx.shadowOffsetY = 0
    this.ctx.shadowBlur = 2
    this.ctx.shadowColor = color
    
    this.ctx.beginPath();
    this.ctx.moveTo(x1,y1);
    this.ctx.lineTo(x2,y2);

    this.ctx.closePath();
    this.ctx.stroke();
  }


  initEvents(){


    if(this.DOM.canvas){
      this.lines = [];

      this.DOM.range.onchange = (e)=>{
        this.drawLine = e.target.value

        let range = ((this.drawLine - 3) / (30 - 3)) + 1
        gsap.set(this.DOM.brush, {'--brush': range})
      }

      

      for(let [i, btn] of this.DOM.colors.entries()){
        btn.onclick = (e)=>{
          this.drawColor = btn.value
          this.DOM.colors[this.active].classList.remove('act')
          this.active = i
          this.DOM.colors[this.active].classList.add('act')
        }

      }

      this.DOM.erase.onclick = (e)=>{
        this.lines = []
        this.ctx.fillStyle = "#2A2A2A";
        this.ctx.fillRect(0, 0, this.DOM.canvas.width, this.DOM.canvas.height);
      }

      this.prevMouseX=null
      this.prevMouseY=null
      
      

      if(this.touch){

        this.DOM.range.ontouchend = (e)=>{
          this.drawLine = e.target.value
  
          let range = ((this.drawLine - 3) / (30 - 3)) + 1
          gsap.set(this.DOM.brush, {'--brush': range})
        }

        this.DOM.canvas.ontouchstart = (e)=>{
          this.paint = true
          let mouseX = e.changedTouches[0].clientX
          let mouseY = e.changedTouches[0].clientY
      
          if (this.prevMouseX==null) {
              this.prevMouseX = mouseX - 1
              this.prevMouseY = mouseY - 1
          }

          if (this.paint) {
            this.drawline(mouseX,mouseY,this.prevMouseX,this.prevMouseY, this.drawColor, this.drawLine)
            this.lines.push({startX: this.prevMouseX, startY: this.prevMouseY, endX: mouseX, endY: mouseY, color: this.drawColor, line: this.drawLine});
          }
        }

        this.DOM.canvas.ontouchend = (e)=>{
          this.paint = false
          this.prevMouseX = null
          this.prevMouseY = null
          
        }
        this.DOM.canvas.ontouchcancel = (e)=>{
          this.paint = false
          this.prevMouseX = null
          this.prevMouseY = null
        }

        this.DOM.canvas.ontouchmove = (e)=>{
          let mouseX = e.changedTouches[0].clientX
          let mouseY = e.changedTouches[0].clientY
      
          if (this.prevMouseX==null) {
              //store these coordinates for next time if they haven't been defined yet
              this.prevMouseX = mouseX
              this.prevMouseY = mouseY
          }
          
          if (this.paint) {
            this.drawline(mouseX,mouseY,this.prevMouseX,this.prevMouseY, this.drawColor, this.drawLine)
            this.lines.push({startX: this.prevMouseX, startY: this.prevMouseY, endX: mouseX, endY: mouseY, color: this.drawColor, line: this.drawLine});
          }
      
          //store these coordinates for next time
          this.prevMouseX = mouseX
          this.prevMouseY = mouseY
        }

        

        
      }else{

        this.DOM.canvas.onmousedown = (e)=>{
          this.paint = true
          let rect = e.target.getBoundingClientRect()
          //get current mouse coords
          let mouseX = (e.clientX - rect.left).toFixed(0)
          let mouseY = (e.clientY - rect.top).toFixed(0)
      
          if (this.prevMouseX==null) {
              //store these coordinates for next time if they haven't been defined yet
              this.prevMouseX = mouseX
              this.prevMouseY = mouseY
          }

          if (this.paint) {
            this.drawline(mouseX,mouseY,this.prevMouseX,this.prevMouseY, this.drawColor, this.drawLine)
            this.lines.push({startX: this.prevMouseX, startY: this.prevMouseY, endX: mouseX, endY: mouseY, color: this.drawColor, line: this.drawLine});
          }
        }


        this.DOM.canvas.onmouseup = (e)=>{
          this.paint = false
        }
  
        this.DOM.canvas.onmouseleave = (e)=>{
          this.paint = false
        }
  
        
        
        this.DOM.canvas.onmousemove = (e)=>{
            //get current mouse coords
            let rect = e.target.getBoundingClientRect()
            let mouseX = (e.clientX - rect.left).toFixed(0)
            let mouseY = (e.clientY - rect.top).toFixed(0)
        
            if (this.prevMouseX==null) {
                //store these coordinates for next time if they haven't been defined yet
                this.prevMouseX = mouseX
                this.prevMouseY = mouseY
            }
            
            if (this.paint) {
              this.drawline(mouseX,mouseY,this.prevMouseX,this.prevMouseY, this.drawColor, this.drawLine)
              this.lines.push({startX: this.prevMouseX, startY: this.prevMouseY, endX: mouseX, endY: mouseY, color: this.drawColor, line: this.drawLine});
            }
        
            //store these coordinates for next time
            this.prevMouseX = mouseX
            this.prevMouseY = mouseY
        }
      }
    }
  }

  removeEvents(){
    
  }

  onResize(){
    if(this.DOM.canvas){
      this.ctx = this.DOM.canvas.getContext("2d")
      this.ctx.imageSmoothingEnabled = true;
      this.DOM.canvas.width = window.innerWidth
      this.DOM.canvas.height = window.innerHeight
      this.ctx.fillStyle = "#2A2A2A";
      this.ctx.fillRect(0, 0, this.DOM.canvas.width, this.DOM.canvas.height);
      for(let line of this.lines){
        this.drawline(line.startX,line.startY,line.endX,line.endY, line.color, line.line)
      } 
    } 
  }
}
