
import Field from '/atoms🧿🧿🧿/Field'
import Chk from '/atoms🧿🧿🧿/Chk'
import Selc from '/atoms🧿🧿🧿/Selc'
import './index.scss'

export default class {
  constructor (el,main,title) {
    this.main = main
    this.base = main.base
    this.DOM = {
      el:el,
      
      send:el.querySelector('.btnSend'),
      sendCanvas:document.querySelector('#sendCanvas'),
    }

    if(this.DOM.el.querySelectorAll('.Afield')){
      this.fields = []
      for(let b of this.DOM.el.querySelectorAll('.Afield')){
        this.fields.push(new Field(b))
      }
    }
    
    if(this.DOM.el.querySelectorAll('.Achk')){
      for(let b of this.DOM.el.querySelectorAll('.Achk')){
        this.fields.push(new Chk(b))
      }
    }

    if(this.DOM.el.querySelectorAll('.Aselect')){
      for(let b of this.DOM.el.querySelectorAll('.Aselect')){
        this.fields.push(new Selc(b))
      }
    }

    
    this.active = 0

    // this.create()
  }

  async create(){
    
    
  }
  async sendMail(check){
    

    if(check == 1){
      for(let a of this.fields){
        a.check()
      }
    }

    if(document.querySelector('canvas')){
      let canvas = document.querySelector('canvas')
      this.cvImage = document.getElementById("canvas-image");
      let image = canvas.toDataURL("image/png");
      this.cvImage.value = image;
    }

    if(this.DOM.el.querySelector('.err')){
      return false
    }
    let info = []
    let formData = new FormData()

    for(let a of this.fields){
      if(!a.DOM.el.classList.contains('Achk')){
        let ob = {label:a.DOM.npt.name, value:a.DOM.npt.value}
        info.push(ob)
        
      }
    }

    if(this.cvImage){
      let w
      let h
      if(this.main.isTouch){
        w = document.querySelector('canvas').attributes.width.value
        h = document.querySelector('canvas').attributes.height.value
      }else{
        w = 800
        h = 370
      }
      let ob = {label:'imagen', value:this.cvImage.value, width: w, height: h}
      info.push(ob)
    }


    formData.set("form", JSON.stringify(info))
    // console.log(formData)
    // console.log(info)
    // console.log(JSON.stringify(info))

    await fetch(this.base+'/wp-json/wp/v2/sendform',{
      method: 'post',
      body: formData

    }).then(response => response.json()).then((data)=>{

      // console.log(data)
      this.DOM.el.classList.add('send')
      gsap.to(this.DOM.el, {opacity:.6, duration:.6, ease: "inOut"})

      setTimeout(() => {
        this.DOM.el.classList.remove('send')
        for(let a of this.fields){
          a.clear()
        }
        if(document.querySelector('canvas')){
          let canvas = document.querySelector('canvas')
          let context = canvas.getContext('2d');
          context.clearRect(0, 0, canvas.width, canvas.height);
          context.fillStyle = "#2A2A2A";
          context.fillRect(0, 0, canvas.width, canvas.height);
    
        }
        gsap.to(this.DOM.el, {opacity:1, duration:.6, ease: "inOut"})
      }, 1200);
      
    }).catch((err)=>{
      console.log(err)
    })
  }
  timeout(ms){
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  initEvents(){
    this.DOM.el.onsubmit = async (ev) =>{
      
      ev.preventDefault()
      this.sendMail(1)
    }

    if(this.DOM.sendCanvas){
      this.DOM.sendCanvas.onclick = async (ev) =>{
      
        ev.preventDefault()
        this.sendMail(0)
      }  
    }
    
    
  }
  removeEvents(){
   

  }
  
  onResize(){
  }
}
