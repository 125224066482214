
//VIEWS
//🟠🔴🔵🟢🟣🟡⚪⚫🟤
import Home from '/views👁️👁️👁️/⚪Home/home.js'
import About from '/views👁️👁️👁️/🟢About/about.js'
import Uikit from '/views👁️👁️👁️/⚙️Uikit/uikit.js'
import Error from '/views👁️👁️👁️/🚫Error/error.js'
import Legal from '/views👁️👁️👁️/📄Legal/legal.js'
import Proyecto from '/views👁️👁️👁️/🔵Proyecto/proyecto.js'
import Proyectos from '/views👁️👁️👁️/🔵🔵🔵Proyectos/proyectos.js'
import Servicios from '/views👁️👁️👁️/🟣Servicios/servicios.js'
import Contact from '/views👁️👁️👁️/🟠Contact/contact.js'
import Landing from '/views👁️👁️👁️/🛬Landing/landing.js'
import Pizarra from '/views👁️👁️👁️/🖌️Pizarra/pizarra.js'


import '/views👁️👁️👁️/⚪Home/styles.js'
import '/views👁️👁️👁️/🟢About/styles.js'
import'/views👁️👁️👁️/⚙️Uikit/styles.js'
import'/views👁️👁️👁️/🚫Error/styles.js'
import'/views👁️👁️👁️/📄Legal/styles.js'
import '/views👁️👁️👁️/🔵Proyecto/styles.js'
import '/views👁️👁️👁️/🔵🔵🔵Proyectos/styles.js'
import '/views👁️👁️👁️/🟣Servicios/styles.js'
import '/views👁️👁️👁️/🟠Contact/styles.js'
import '/views👁️👁️👁️/🛬Landing/styles.js'
import '/views👁️👁️👁️/🖌️Pizarra/styles.js'

// import Uikit from '/views👁️👁️👁️/Uikit⚙️'
// import Legal from '/views👁️👁️👁️/🔗Legal'

  




export function createViews(){


  this.pages = new Map()
    this.pages.set('home', new Home(this.main))
    this.pages.set('error', new Error(this.main))
    this.pages.set('uikit', new Uikit(this.main))
    this.pages.set('page', new Legal(this.main))
    this.pages.set('about', new About(this.main))
    this.pages.set('proyecto', new Proyecto(this.main))
    this.pages.set('proyectos', new Proyectos(this.main))
    this.pages.set('servicios', new Servicios(this.main))
    this.pages.set('contact', new Contact(this.main))
    this.pages.set('landing', new Landing(this.main))
    this.pages.set('pizarra', new Pizarra(this.main))
}

