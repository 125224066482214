export function addPop() {
	window.addEventListener('popstate', (ev)=>this.onPopState(ev), {passive: true})
}

// EVENTS

// Patrás y palante
export function onPopState(ev) {
	ev.preventDefault()
	//Revisa punto 2 en checks
	this.onChange({
		url: window.location.pathname,
		push: false,
	})
}

// Empieza el cambio
export async function onChange({
	url = null,
	link = null,
	id = null,
	img = null,
	anchor = null
}) {
	url = url.replace(window.location.origin, '')
	if (this.isLoading || this.url === url) return
	this.lenis.stop()
	this.issame = 0
	this.page.isVisible = false
	this.isLoading = true
	let time = 12

	this.url = url

	let functowait = []
	if (this.nav.isOpen == 1) {
		this.nav.closeMenu()
		this.nav.isOpen = 0
		time = 12
	}
	// functowait.push()
	// await this.page.hide()

	document.body.style.pointerEvents = 'none'

	const request = await window.fetch(url, {
		headers: {
			'X-Requested-With': 'XMLHttpRequest',
		},
	})

	const response = await request.text()
	var push = true

	if (this.canvas) {
		this.canvas.cleanTemp()
	}
	await this.page.animOut()
	this.loader.showPop()

	Promise.all([
		this.timeout(time),
		this.onRequest({
			push,
			response,
			url,
			id,
			anchor
		}),
	]).then(() => {
		this.newView(anchor)
	})
}

// llamada de la nueva URL
export async function onRequest({push, response, url, id,anchor}) {
	const html = document.createElement('div')

	html.innerHTML = response
	if (html.querySelector('title')) {
		document.title = html.querySelector('title').textContent
	}
	this.content = html.querySelector('#content')

	if (push) {
		window.history.pushState({}, document.title, url)
	}

	await this.page.hide()
	this.lenis.scrollTo(0, {immediate: true, lock: true, force: true})
	this.page.DOM.el.remove()

	this.template = this.content.dataset.template
	this.newpage = this.pages.get(this.template)
	if(this.template === 'landing'){
		this.nav.hide()
	}else{
		this.nav.show()
	}
	this.newpage.id = this.content.dataset.id

	this.newpage.ispop = 1
	await this.newpage.create(this.content, this.main)
	if (this.canvas) {
		await this.canvas.createTemp(this.template)
	}
	if (anchor) {
		if(document.querySelector('#' + anchor)){
			await this.timeout(300)

			document.querySelector('#' + anchor).scrollIntoView({alignToTop: true, block: 'start'})
		}
	}
}

export async function newView() {
	// console.log('estoy aquí')
	// this.loader.hide()
	
	if (this.mouse) {
		this.mouse.reset()
	}

	document.body.style.pointerEvents = ''
	this.isLoading = false

	this.loader.hidePop()
	this.newpage.show(0)

	if (this.canvas) {
		this.canvas.show()
	}

	this.page = this.newpage
	let state = this.page.start(0)

	this.newpage.ispop = 0

	this.addControllers()
	
	this.lenis.start()
	
}

//CHECKS
//1.Onchange
//2.Pops palante y patrás
////3.tener en cuenta menú abierto o modales
////4.tener en cuenta transiciones entre vistas del mismo tipo ( de post a post, por ejemplo )

export function resetLinks() {
	const links = document.querySelectorAll('a')

	const actual = window.location.href
	for (let link of links) {
		if (link.classList.contains('Awrite')) {
			// link.onmouseenter = () => this.writeFn(link)
			// link.onmouseenter = () => {
			//   this.main.events.animglobal.detail.el = link
			//   document.dispatchEvent(this.main.events.animglobal)
			// }
		}

		let isLocal = link.href.indexOf(this.main.base) == 0
		const isAnchor = link.href.indexOf('#') > -1 && link.href.indexOf(this.main.base) == 0

		if (link.dataset.type && !isAnchor) {
			if (import.meta.env.DEV) {
				isLocal = true
				if (link.dataset.type) {
					link.href = '/' + link.dataset.type + '.html'
				}
			}
			link.removeAttribute('data-type')
		}

		if (isLocal || isAnchor) {
			link.onclick = async (event) => {
				event.preventDefault()

				if(link.dataset.anchor){
					document.documentElement.dataset.anchor = link.dataset.anchor	
				}
				
				if (!isAnchor) {
					this.onChange({
						url: link.href,
						id: link.dataset.id,
						link: link,
					})
				} else {
					if (this.nav.isOpen == 1) {
						this.nav.isOpen = 0
						this.nav.closeMenu()
						await this.timeout(450)
					}
					if (link.href.split('#').length == 2) {

						
						
						if(actual != link.href.split('#')[0]){
							this.onChange({
								url: link.href.split('#')[0],
								id: link.dataset.id,
								link: link,
								anchor: link.href.split('#')[1],
							}
						)

						}else{
							
							let offset = 0
							if (link.dataset.offset) {
								offset = parseInt(link.dataset.offset)
							}
							this.lenis.scrollTo('#' + link.href.split('#')[1], {offset: offset, duration:2})
						}
						
					}
				}
			}
		} else if (
			link.href.indexOf('mailto') === -1 &&
			link.href.indexOf('tel') === -1
		) {
			link.rel = 'noopener'
			link.target = '_blank'
		}
		//CLEAN CLASS
		if (actual == link.href) {
			link.classList.add('actLink')
		} else {
			link.classList.remove('actLink')
		}
	}
}
