

import './index.scss'

export default class {
  constructor (el,device) {

    this.el = el
    this.device = device

    this.DOM = {
      el:this.el,
      logo: this.el.querySelector('.cnt_logo'),
      emojis: this.el.querySelectorAll('.cnt_logo_emoji span'),
    }
    // console.log(this.device)
    this.active = 0
    this.activeEmoji = 0
    this.create()
  }
  
  async create(){
    
    
  }

  initEvents(){

    if(this.device <= 0){
      this.DOM.logo.onmouseenter = ()=>{

        this.DOM.emojis[this.activeEmoji].classList.remove('act')
        this.activeEmoji = Math.floor(Math.random() * (this.DOM.emojis.length))
        this.DOM.emojis[this.activeEmoji].classList.add('act')
      }
    }


  }


  
  
}