//import SplitType from 'split-type'

export default class {
	constructor(obj, device, touch) {
		this.el = obj.el
    this.parent = obj.el.parentElement
		this.pos = obj.pos
		this.device = device
		this.touch = touch

		this.DOM = {
			el: obj.el,
			images: this.parent.querySelectorAll('.images .image'),
		}

    
		this.active = 0
    this.isupdate = 0


    this.h = window.innerHeight
    

    this.create()
	}

	async create() {

    this.anim = gsap.timeline({ paused: true })

    for(let [i, img] of this.DOM.images.entries()){
      this.anim.to(img, {y: -100+'vh', duration: 1,ease: 'power2.inOut'}, 0)
    }
		
  }


	check(entry, pos) {
		let vis = false

		if (entry.isIntersecting == true) {
      vis = true
			this.start()
		} else {
      vis = false
			this.stop()
		}
		return vis
	}

	start(){
    if(this.active === 0){
      this.anim.reverse()
    }
    this.active = 1
    
  }
  
  stop(){
    if(this.active === 1){
      this.anim.play()
    }
    this.active = 0
    
  }


  update(pos){

  }

  onResize(){
    
  }
}