//import SplitType from 'split-type'

export default class {
	constructor(obj, main) {
		this.el = obj.el
    this.parent = obj.el.parentElement
		this.pos = obj.pos
    this.main = main
		this.device = main.device
		this.touch = main.touch

		this.DOM = {
			el: obj.el,
			hold: this.parent.querySelector('.stck_m'),
      servs: this.parent.querySelectorAll('.serv_info'),
      servInfoHolds: this.parent.querySelectorAll('.serv_info_h'),
      servTitle: this.parent.querySelectorAll('.cnt_title'),
		}

		
		this.active = 0
    this.isupdate = 1

    this.actProj = 0

		

    this.h = window.innerHeight
    
    this.animstick = {
      active:0,
      current:0,
      limit:0,
      start:0,
      prog:0,
      total:0,
    }
    
    this.create()
	}

	async create() {
    this.anim = gsap.timeline({paused:true})
    .to(this.DOM.servTitle, {opacity:0, duration:.4, ease: 'power2.out'}, 0)  
    .to(this.DOM.servs[0], {width: '100%'}, 0)
    
    for(let [i, serv] of this.DOM.servs.entries()){
      if(i+1 == this.DOM.servs.length) return
      this.anim.to(this.DOM.servs[i], {width: 0}, '>')
      this.anim.to(this.DOM.servs[i+1], {width: '100%'}, '<')
    }

  }


	check(entry, pos) {
		let vis = false

		if (entry.isIntersecting == true) {
      vis = true
			this.start()
		} else {
      vis = false
			this.stop()
		}
		return vis
	}

	start(){
    this.active = 1
  }
  
  stop(){
    this.active = 0
  }


  update(pos){

    this.animstick.current = pos - this.animstick.start
    
    //CLAMP
    this.animstick.current = clamp(0, this.animstick.limit, this.animstick.current)
    this.animstick.prog = (this.animstick.current  / this.animstick.limit).toFixed(4)

    this.anim.progress(this.animstick.prog)

  }

  onResize(){
    this.parent.style.height = (this.DOM.servs.length * 100) + 'vh'

    this.w = window.innerWidth
    this.h = window.innerHeight
    //Start es para coger la posición inicial
    // El limit siempre será el tamaño de la pantalla + el tamaño del IO ( es todo el espacio que se va a ver)
    // El ratio lo hago para calcular cuando termina la primera parte ( hasta que está completamente dentro de la pantalla) y la segunda ( el resto )
    this.animstick.start = parseInt((this.DOM.el.getBoundingClientRect().y + window.scrollY ).toFixed(0))
    this.animstick.limit = parseInt((this.DOM.el.clientHeight - this.h).toFixed(0))
    // this.animstick.ratio = (this.DOM.el.clientHeight / this.h).toFixed(4)
    
    //* El kill y el create solo si el anim está hecho en absoluto y no con porcentaje
    //* Importante que todos los elementos que vayas a matar tengan un fromTo
    if(this.anim){
      // this.anim.kill()
      // this.create()
      this.update(window.scrollY)
    }


  }
}