

import Splide from '@splidejs/splide';


export default class {
	constructor(el, device) {
		this.DOM = {
			el: el,
			slider: el.querySelector('.splide'),
			
		}
		this.device = device
		this.active = 0

		this.toRight = 0
		this.toLeft = 0

		this.create()
	}

	async create() {
		if(this.device > 1){
			this.perPage = 1.4
		}else{
			this.perPage = 2
		}

		
		if(this.DOM.el.querySelector('.splide')){
			this.splide = new Splide(this.DOM.el.querySelector('.splide'), {
				type : 'loop',
				start: 1,
				perPage: this.perPage,
				focus: 'center',
				arrows: false,
				clones: this.DOM.el.querySelectorAll('.splide__slide').length,
				updateOnMove: true,
			}).mount()
		}
		
	}

	async start() {
		
		
	}

	timeout(ms){
    return new Promise(resolve => setTimeout(resolve, ms))
  }

	initEvents() {
		if(this.DOM.slider){
			this.DOM.slider.onclick = (e) =>{
				if(e.x > this.DOM.slider.clientWidth/2){
					this.splide.go('+')
				}else{
					this.splide.go('-')
				}
			}

			this.DOM.slider.onmousemove = (e) =>{
				if(e.x > this.DOM.slider.clientWidth/2){
					if(this.toRight === 1) return
					this.toRight = 1
					this.toLeft = 0
					document.documentElement.classList.remove('mouseLeft')
					document.documentElement.classList.add('mouseRight')
				}else{
					if(this.toLeft === 1) return
					this.toLeft = 1
					this.toRight = 0
					document.documentElement.classList.remove('mouseRight')
					document.documentElement.classList.add('mouseLeft')
				}
			}

			this.DOM.slider.onmouseleave = (e) =>{
				this.toLeft = 0
				this.toRight = 0
				document.documentElement.classList.remove('mouseRight')
				document.documentElement.classList.remove('mouseLeft')
			}
		}

	}

	removeEvents() {}

	onResize() {
		
	}


}
