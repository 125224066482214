
//import SplitType from 'split-type'


export default class {
  constructor (obj,device,touch) {

    this.el = obj.el    
    this.parent = obj.el.parentNode

    this.pos = obj.pos
    this.device = device
    this.touch = touch
    
    this.DOM = {
      el:obj.el,
      parent:this.parent,
      emojis: this.parent.querySelectorAll('.cnt_logo_emoji span'),
    }

    this.active = 0
    this.isupdate = 0
    this.w = window.innerWidth
    this.h = window.innerHeight

    this.create()
  }
  
  async create(){
  
  }

  

  start(){
    if(this.touch && this.DOM.emojis.length > 0 && this.active == 0){
      this.randomEmoji = this.randNum(this.DOM.emojis.length - 1)
      this.lastEmoji = this.randomEmoji 
      this.DOM.emojis[this.randomEmoji].classList.add('act')
      
    }
    document.documentElement.classList.add('footerAct')
    this.active = 1
    
  }
  
  stop(){
    if(this.active == 1){
      if(this.touch && this.DOM.emojis.length > 0){
        this.DOM.emojis[this.lastEmoji].classList.remove('act')
        
      }
      document.documentElement.classList.remove('footerAct')
    }

    this.active = 0
    
  }
  
  check(entry,pos){

    let vis = false

    if(entry.isIntersecting == true){
      vis = true
      this.start()
    }
    else{
      this.stop()
    }

    return vis

  }

  initEvents(){
    

  }
  removeEvents(){
   

  }
  touchScroll(){

  }

  randNum(max){
    return Math.floor(Math.random() * max);
  }

  onResize(pos){

    this.w = window.innerWidth
    this.h = window.innerHeight
   
  }
  
  
}
