import Page from '/js🧠🧠🧠/page👁️/pagemain.js'

//COMPS
import Intro from './0Intro'
import Scrollintro from './0Intro/io.js'
import Imgone from './0Intro/imgone.js'

import Services from './3Services'

import Scrollprojs from './2Proyectos/io.js'

class Home extends Page {
	constructor(main) {
		super(main)
	}

	async create(content, main, temp = undefined) {
		super.create(content, main)
		if (temp != undefined) {
			document.querySelector('#content').insertAdjacentHTML('afterbegin', temp)
		} else {
			let data = await this.loadRestApi(
				this.main.base + '/wp-json/wp/v2/pages/',
				content.dataset.id,
				content.dataset.template
			)
			document
				.querySelector('#content')
				.insertAdjacentHTML('afterbegin', data.csskfields.main)
		}
		this.el = document.querySelector('main')

		this.DOM = {
			el: this.el,
		}

		gsap.set(this.DOM.el, { opacity:0})

		await this.loadImages()
		await this.loadVideos()

		await this.createComps()
		await this.createIos()

		await this.getReady()
	}
	iOpage(animobj) {
		if (animobj.el.classList.contains('iO-scrollintro')) {
			animobj.class = new Scrollintro(animobj, this.main.device)
		}
		else if (animobj.el.classList.contains('iO-scrollprojs')) {
			animobj.class = new Scrollprojs(animobj, this.main.device)
		}
		else if (animobj.el.classList.contains('iO-imgone')) {
			animobj.class = new Imgone(animobj, this.main.device)
		}


		return animobj
	}

	async createComps() {
		await super.createComps()
		if (this.DOM.el.querySelector('.home_intro')) {
			this.components.intro = new Intro(
				this.DOM.el.querySelector('.home_intro'),
				this.main.device
			)
		}

		if (this.DOM.el.querySelector('.home_servs')) {
			this.components.services = new Services(
				this.DOM.el.querySelector('.home_servs'),
				this.main.device
			)
		}
	}

	async animIntro(val) {
		
		gsap.to(this.DOM.el, { opacity: 1, duration: .9, ease: 'power2.inOut' })
		if (this.components.intro) {
			await this.components.intro.start()
		}
		
		return val
	}

	async animOut() {
		await gsap.to(this.DOM.el, { opacity: 0, duration: .6, ease: 'Power2: inOut'})
	}
}

export default Home
