import Page from '/js🧠🧠🧠/page👁️/pagemain.js'

//COMPS
import Slider from './6Slider'
import Testimonios from './7Testimonios'


import Form from '/components🦾🦾🦾/Contacto'

class Home extends Page {
  constructor (main) {
    super(main)
  }

  async create(content,main,temp=undefined) {
    super.create(content,main)
    if(temp!=undefined){

      document.querySelector('#content').insertAdjacentHTML('afterbegin',temp)
    }
    else{
      let data = await this.loadRestApi(this.main.base+'/wp-json/wp/v2/pages/',content.dataset.id,content.dataset.template)
      document.querySelector('#content').insertAdjacentHTML('afterbegin',data.csskfields.main)
    }
    
  
    this.el = document.querySelector('main')
    

    this.DOM = {
      el:this.el,
      logo: this.el.querySelector('.landing_footer .cnt_logo'),
      emojis: this.el.querySelectorAll('.landing_footer .cnt_logo_emoji span'),
    }

    gsap.set(this.DOM.el, {opacity: 0})

    await this.loadImages()
    await this.loadVideos()
    


    await this.createComps()
    await this.createIos()
    

    await this.getReady()

    this.initEvents()
  }
  iOpage(animobj){
    // if (animobj.el.classList.contains('iO-scrollservices')) {
		// 	animobj.class = new Scrollservices(animobj, this.main)
		// }
    return animobj
  }

  
  
  async createComps(){

    // if (this.DOM.el.querySelector('.servicios_main')) {
		// 	this.components.servicios = new Servicios(
		// 		this.DOM.el.querySelector('.servicios_main'),
		// 		this.main.device
		// 	)
		// }


    //SLIDERS
    if(this.DOM.el.querySelector('.landing_slider')){
      this.components.sliders = []
      for(let a of this.DOM.el.querySelectorAll('.landing_slider')){
        this.components.sliders.push(new Slider(a, this.main.device))
      }
    }

    //TESTIMONIOS 
    if(this.DOM.el.querySelector('.landing_test')){
      this.components.tests = []
      for(let a of this.DOM.el.querySelectorAll('.landing_test')){
        this.components.tests.push(new Testimonios(a, this.main.device))
      }
    }
    //FORMS
    if(this.DOM.el.querySelector('.landing_form form')){
      this.components.forms = []
      for(let a of this.DOM.el.querySelectorAll('.landing_form form')){
        this.components.forms.push(new Form(a, this.main))
      }
    }

    await super.createComps()
   

  }

  initEvents(){
    this.activeEmoji = 0
    if(this.main.device <= 0){
      this.DOM.logo.onmouseenter = ()=>{
        this.DOM.emojis[this.activeEmoji].classList.remove('act')
        this.activeEmoji = Math.floor(Math.random() * (this.DOM.emojis.length))
        this.DOM.emojis[this.activeEmoji].classList.add('act')
      }
    }
  }


  async animIntro(val){

    gsap.to(this.DOM.el, { opacity: 1, duration: .9, ease: 'Power2: inOut'})
    return val
   
  }

  async animOut(){
    await gsap.to(this.DOM.el, { opacity: 0, duration: .6, ease: 'Power2: inOut'})
    
  }

}




export default Home