
export default class {
  constructor (el,device) {


    this.DOM = {
      el:el,
      servs: el.querySelectorAll('.serv'),
    }
    this.device = device
    this.active = 0
    this.create()

  }

  async create(){
    
  
  }
  
  async start(){
  }
  initEvents(){

    for(let [i,serv] of this.DOM.servs.entries()){
      serv.querySelector('.serv_title').onclick = ()=>{
        if(serv.classList.contains('act')){
          this.DOM.servs[this.active].classList.remove('act')
          return
        } 
        this.DOM.servs[this.active].classList.remove('act')
        this.active = i
        this.DOM.servs[this.active].classList.add('act')
      }
    }
  }

  removeEvents(){
    
  }

  onResize(){
  }
}
