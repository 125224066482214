// import './index.scss'
//import SplitType from 'split-type'

export default class {
	constructor(el, device) {
		this.DOM = {
			el: el,
			servs: el.querySelectorAll('.servs_list .serv'),
			servsHold: el.querySelector('.servs_list'),
			hold: el.querySelector('.servs_images'),
			images: el.querySelectorAll('.servs_images img'),
		}
		this.device = device
		this.active = 0

		this.create()
	}

	async create() {
		
		// this.DOM.servs[this.active].classList.add('act')
		// gsap.to(this.DOM.images[this.active], {opacity: 1, filter: 'blur(0px)', duration:.6, ease: 'power2.inOut'})
	}

	async start() {}

	initEvents() {

		for(let  [ i, serv ] of this.DOM.servs.entries()) {

			serv.onmouseenter = (e) => {
				gsap.to(this.DOM.images[this.active], {opacity: 0,filter: 'blur(1px)' , duration:.6, ease: 'power2.inOut'})
				this.DOM.servs[this.active].classList.remove('act')
				this.active = i
				this.DOM.servs[this.active].classList.add('act')
				gsap.to(this.DOM.images[this.active], {opacity: 1, filter: 'blur(0px)', duration:.6, ease: 'power2.inOut'})
			}
		}

		this.DOM.servsHold.onmouseenter = () => {
			this.DOM.hold.classList.add('act')
		}

		this.DOM.servsHold.onmouseleave = () => {
			this.DOM.hold.classList.remove('act')
			gsap.to(this.DOM.images[this.active], {opacity: 0,filter: 'blur(1px)' , duration:.6, ease: 'power2.inOut'})
			this.DOM.servs[this.active].classList.remove('act')
		}

		// this.DOM.el.querySelector('.servs_list').onmouseleave = () => {
		// 	gsap.to(this.DOM.hold, {'--mask': 0, duration:.3})
		// 	gsap.to(this.DOM.images[this.active], {opacity: 0,filter: 'blur(1px)', duration:.6, ease: 'power2.inOut'})

		// }

	}

	removeEvents() {}

	onResize() {}
}
