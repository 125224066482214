// import './index.scss'
//import SplitType from 'split-type'

export default class {
	constructor(el, device) {
		this.DOM = {
			el: el,
			logo: el.querySelectorAll('.logo'),
			imagesHold: el.querySelector('.images'),
			images: el.querySelectorAll('.images .image'),
			title: el.querySelector('.cnt_title'),
			titleEls: el.querySelectorAll('.cnt_title_tr span'),
		}
		this.device = device
		this.active = 0

		
		this.create()
	}

	async create() {
		this.animintro = gsap.timeline({ paused: true })
		if(this.device <= 1){
			for(let l of this.DOM.logo){
				gsap.set(l,{y:100+'%'})
			}
			for(let l of this.DOM.logo){
				this.animintro.to(l, {y: 0, duration: .9, ease: 'power2.inOut'},0)
			}
		}else{
			this.animintro
				.to(this.DOM.title, {y:0, opacity: 1, duration: 1.4 , ease: 'power2.inOut'},0)

				.fromTo(this.DOM.titleEls[0],{opacity:1}, {opacity: 0, duration: .6,ease: 'power2.inOut'}, 1.4)
				.fromTo(this.DOM.titleEls[1],{opacity:0}, {opacity: 1, duration: .6,ease: 'power2.inOut'}, '>')
				.fromTo(this.DOM.titleEls[1],{opacity:1}, {opacity: 0, immediateRender:false, duration: .6,ease: 'power2.inOut'}, '>')
				.fromTo(this.DOM.titleEls[2],{opacity:0}, {opacity: 1, duration: .6,ease: 'power2.inOut'}, '>')


			this.animImages = gsap.timeline({ paused: true })
			.to(this.DOM.imagesHold, {filter:'blur(0)', opacity: 1, duration: 1, ease: 'power2.inOut'},.4)

			.fromTo(this.DOM.images[0],{y:0}, {y: -30+'vh', duration: 2,ease: 'power2.inOut'}, 0)
			.fromTo(this.DOM.images[1],{y:0}, {y: -55+'vh', duration: 2,ease: 'power2.inOut'}, 0)
			.fromTo(this.DOM.images[2],{y:0}, {y: -40+'vh', duration: 2,ease: 'power2.inOut'}, 0)
		}
		
	}

	async start() {
		
		this.animintro.play()
		if(this.device > 1){
			await this.animImages.play()
		}
		
	}
	initEvents() {}

	removeEvents() {}

	onResize() {}
}
