
import './index.scss'



class Nav {
  constructor (main) {
   
    this.main = main
    this.isOpen = 0

  }

  async create (temp) {

    document.querySelector('body').insertAdjacentHTML('afterbegin',temp)

   let el = document.querySelector('.nav')
    this.DOM = {
      el:el,
      burger:el.querySelector('.nav_burger'),
      close:el.querySelector('.nav_close'),
      top:el.querySelector('.nav_top'),
      side:el.querySelector('.nav_side'),
    }

    gsap.set(this.DOM.top, {y: -100+'%'})
    if(this.DOM.side){
      gsap.set(this.DOM.side, {x: 100+'%'})
    }
    
    this.DOM.el.style.opacity = 0
   

    this.initEvents()
  }

  async openMenu(){
    

    document.documentElement.classList.add('act-menu')
    document.dispatchEvent(this.main.events.openmenu)
  }
  
  async closeMenu(){
    document.documentElement.classList.remove('act-menu')
    document.dispatchEvent(this.main.events.closemenu)
    
  }

  async show(){
    gsap.to(this.DOM.top, {y: 0, duration:.6, ease: 'power2.inOut'})
    if(this.DOM.side){
      gsap.to(this.DOM.side, {x: 0, duration:.6,delay:1, ease: 'power2.inOut'})
    }
    this.DOM.el.style.opacity = 1
  }
  async hide(){

    gsap.to(this.DOM.top,{y: -100+'%', duration:.6, ease: 'power2.inOut'})
    if(this.DOM.side){
      gsap.to(this.DOM.side, {x: 100+'%', duration:.6, ease: 'power2.inOut'})
    }
    // this.DOM.el.style.opacity = 0
  }

  initEvents(){
    if(this.DOM.burger){
      this.DOM.burger.onclick = () =>{
        if(this.isOpen == 1){
          this.closeMenu()
          this.isOpen = 0
        }
        else{
          this.openMenu()
          this.isOpen = 1
        }
      }
    }
    if(this.DOM.close){
      this.DOM.close.onclick = () =>{
        if(this.isOpen == 1){
          this.closeMenu()
          this.isOpen = 0
        }
        else{
          this.openMenu()
          this.isOpen = 1
        }
      }
    }

    
  }
  

  onResize(){
  }
  update(time){
  }


  timeout(ms){
    return new Promise(resolve => setTimeout(resolve, ms))
  }
}



export default Nav