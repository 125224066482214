

//* Funciones de control
export  function stopScroll(){
  this.isVisible = 0
}
export  function startScroll(){
  this.isVisible = 1
}

//* Función para Smooth
// REVISAR HACER LOS FOR DE LAS ANIMACIONES ASÍNCRONAS POR EL REFLOW ( Mejora rendimiento )



export function animIosScroll(){
  if(this.isVisible == 0){
    return false
  }

    for(let [i,a] of this.iosupdaters.entries()){
      
      a.class.update(window.scrollY)
      
    }
}
