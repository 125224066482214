import Page from '/js🧠🧠🧠/page👁️/pagemain.js'

//COMPS
import Team from './3Team'



class Home extends Page {
  constructor (main) {
    super(main)
  }

  async create(content,main,temp=undefined) {
    super.create(content,main)
    if(temp!=undefined){

      document.querySelector('#content').insertAdjacentHTML('afterbegin',temp)
    }
    else{
      let data = await this.loadRestApi(this.main.base+'/wp-json/wp/v2/pages/',content.dataset.id,content.dataset.template)
      document.querySelector('#content').insertAdjacentHTML('afterbegin',data.csskfields.main)
    }
    this.el = document.querySelector('main')
    

    this.DOM = {
      el:this.el
    }

    gsap.set(this.DOM.el,{opacity:0})

    
    


    await this.createComps()
    await this.createIos()
    await this.loadImages()
    await this.loadVideos()
    

    await this.getReady()
  }
  iOpage(animobj){
    // if(animobj.el.classList.contains('iO-scrollLine')){
    //   animobj.class = new Introio(animobj,this.main.device) 
      
    // } 
    return animobj
  }

  
  
  async createComps(){
    await super.createComps()
    if(this.DOM.el.querySelector('.about_team')){
      this.components.team = new Team(this.DOM.el.querySelector('.about_team'),this.main.device)
    
    }

  }


  async animIntro(val) {
		gsap.to(this.DOM.el, { opacity: 1, duration: .9, ease: 'power2.inOut' })
		if (this.components.intro) {
			this.components.intro.start()
		}

		return val
	}

	async animOut() {
		await gsap.to(this.DOM.el, { opacity: 0, duration: .6, ease: 'Power2: inOut'})
	}
}




export default Home