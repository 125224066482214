
export default class {
  constructor (obj,device,isTouch) {
    this.el = obj.el
    
    this.DOM = {
      el : this.el.parentNode,
      real : this.el.parentNode.querySelector('.Stext_real'),
      fake : this.el.parentNode.querySelector('.Stext_fake'),
      hold : this.el.parentNode.querySelector('.Stext_hold'),

    }

    this.act = 0
    this.isupdate = 2
    

    this.speed = {
      current:0,
      target:0,
      static:0,
      plus:0
    }

    this.ctr = {
      actual:0,
      current:0,
      limit:0,
      start:0,
      prog:0,
      progt:0,
      stop:0,
    }
    this.create()
  }

  
  


  create(){
    while(this.DOM.real.clientWidth < window.innerWidth){
      this.DOM.real.appendChild(this.DOM.real.querySelector('.Sel').cloneNode(true));
    }
    this.DOM.fake.innerHTML = this.DOM.real.innerHTML
    
    this.createAnim()
  }

  createAnim(){
    if(this.DOM.el.classList.contains('Stext-hov')){
      this.animspeed = 30
    }else{
      this.animspeed = 20
    }

    this.anim = gsap.timeline({paused:true,repeat:-1})
    .fromTo(this.DOM.real,{x:0},{x:-100+'%',ease:'none',duration:this.animspeed},0)

    this.animin = gsap.timeline({paused:true})
    .fromTo(this.DOM.hold,{yPercent:100},{yPercent:0,ease:'power3.inOut',duration:.8},0)
    .fromTo(this.speed,{plus:4},{plus:0,ease:'power3.inOut',duration:2,onStart:()=>{
      this.ctr.stop = 1
      
    }},0)
    
    this.animctr = gsap.timeline({paused:true})
    // .fromTo(this.DOM.hold,{yPercent:50,scale:.8},{yPercent:0,scale:1,duration:1,ease:'none'},0)
  }

  check(entry,pos){
    let vis = false
    if(entry.boundingClientRect.height == 0){
      return false
    }
    
    if(entry.isIntersecting == true){
      vis = true
      this.start()

    }
    else{
      
      this.stop()
    }


    return vis

  }
  async start(){
    if(this.active == 1){
      return false
    }
    if(this.ctr.stop == 0){
      this.animin.play()
    }
    this.active = 1
    this.anim.play()
   

  }
  
  stop(){
    if(this.active == 0){
      return false
    }
    this.active = 0
    this.anim.pause()
    
    this.ctr.prog = this.ctr.progt
    this.animctr.progress(this.ctr.prog)
  }

  initEvents(){

    if(this.DOM.el.classList.contains('Stext-hov')){
      this.DOM.el.onmouseenter = (e)=>{
        this.anim.pause()
      }
      this.DOM.el.onmouseleave = (e)=>{
        this.anim.play()
      }
    }
   

  }
  removeEvents(){

  }
  update(speed,y){
    if(this.active == 0 ){
      return false
    }

    //SPEED
    // this.speed.target = ((speed*.6)).toFixed(3)
    // this.speed.current = window.lerp(this.speed.current,this.speed.target,0.06)
    // this.anim.timeScale(1+this.speed.current+this.speed.plus)

    //POSITION
    this.ctr.current =  (y + this.h)  - this.ctr.start
    // this.ctr.current = window.clamp(0, this.ctr.limit, this.ctr.current)
    this.ctr.progt = ((this.ctr.current  / this.ctr.limit))
    this.ctr.prog = window.lerp(this.ctr.prog , this.ctr.progt , .3)    
    this.animctr.progress(this.ctr.prog)


  }

  onResize(pos){
    this.h = window.innerHeight


  

    let bound = this.el.getBoundingClientRect()
   
    this.ctr.start =  parseInt((bound.y + pos))
    this.ctr.limit = bound.height * 2

  }
  
  
}
