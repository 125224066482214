'use strict';


import { lerp } from '/js🧠🧠🧠/defaults/math🧮.js'



import './index.scss'


export default class {
  constructor(main){
    this.main = main

    this.position = {
      x: window.innerWidth / 2,
      y: window.innerHeight / 2
    }
    this.current = {
      x: window.innerWidth / 2,
      y: window.innerHeight / 2
    }
    this.active = 0
    this.speed = 0

    this.duration = .3

    this.initEvents()
    
  }
  async create () {
    
    this.element = document.createElement('div')
    this.element.className = 'mouse'



    this.ball = document.createElement('div')
    this.ball.className = 'mouse_ball'
    this.ball.innerHTML = "<span>Ver más</span>"
    this.element.appendChild(this.ball)

    this.arrow = document.createElement('div')
    this.arrow.className = 'mouse_arrow'
    this.arrow.innerHTML = `<svg viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M10.332 27.3337L13.6654 27.3337L13.6654 7.33366L16.9987 7.33366V4.00032L13.6654 4.00032V0.666992L10.332 0.666992V4.00032L6.9987 4.00032V7.33366L10.332 7.33366L10.332 27.3337ZM3.66536 10.667L3.66536 7.33366H6.9987L6.9987 10.667L3.66536 10.667ZM3.66536 10.667L3.66536 14.0003H0.332031L0.332031 10.667H3.66536ZM20.332 10.667V7.33366L16.9987 7.33366V10.667H20.332ZM20.332 10.667L20.332 14.0003H23.6654L23.6654 10.667H20.332Z" fill="#2A2A2A"/>
                            </svg>`
    this.element.appendChild(this.arrow)


    document.body.appendChild(this.element)
  
  }
  update () {
    if(this.active==0){
      return false
    }
    let targetX = this.position.x
    let targetY = this.position.y
    // this.current.x = lerp(this.current.x, targetX, .08)
    // this.current.y = lerp(this.current.y, targetY, .08)
    this.current.x = targetX
    this.current.y = targetY


    gsap.to(this.element,  {
      x:this.current.x - (this.element.clientWidth / 2),
      y:this.current.y - (this.element.clientHeight / 2),
      duration:this.duration
      
    })

  }


  async start(){
    this.active = 1
  }
  

  initEvents() {
    window.addEventListener('mousedown',()=>{
      document.documentElement.classList.add('mouse-down')
    })
    window.addEventListener('mouseup',()=>{
      document.documentElement.classList.remove('mouse-down')
    })

  }




  mouseHoverIn(el){
    document.documentElement.classList.add("mouseHover")
    if(el.classList.contains('mouseImage')){
      document.documentElement.classList.add("mouseImage")
    }
    if(el.classList.contains('mouseCnv')){
      document.documentElement.classList.add("mouseCnv")
      this.duration = 0
    }
  }


  mouseHoverOut(el){
    document.documentElement.classList.remove("mouseHover")
    if(el.classList.contains('mouseImage')){
      document.documentElement.classList.remove("mouseImage")
    }
    if(el.classList.contains('mouseCnv')){
      document.documentElement.classList.remove("mouseCnv")
      this.duration = .3
    }
  }









  reset(){
    this.cleanEvs()

    this.mouseHover = document.querySelectorAll('a, button, select, input, textarea, .mouseCnv, .detX_click')
    if(this.mouseHover){
      for(let el of this.mouseHover ){
        el.addEventListener('mouseenter',()=>this.mouseHoverIn(el))

        el.addEventListener('mouseleave',()=>this.mouseHoverOut(el))
      }
    }
    
  }



  cleanEvs(){
    document.documentElement.classList.remove("mouseHover")
    document.documentElement.classList.remove("mouseImage")
    document.documentElement.classList.remove("mouseCnv")
    this.duration = .3
    if(this.mouseHover){
      for(let el of this.mouseHover ){
        if(!el){
          el.removeEventListener('mouseenter',(e)=>this.mouseHoverIn(e,el))
          // el.removeEventListener('mousemove',(e)=>this.mouseFollowMove(e,el))
          el.removeEventListener('mouseleave',()=>this.mouseHoverOut(el))
        }
      }
    }
  }


}
