import Page from '/js🧠🧠🧠/page👁️/pagemain.js'

//COMPS
import Servicios from './0Intro'



import Scrollservices from './1Servicios/io.js'



class Home extends Page {
  constructor (main) {
    super(main)
  }

  async create(content,main,temp=undefined) {
    super.create(content,main)
    if(temp!=undefined){

      document.querySelector('#content').insertAdjacentHTML('afterbegin',temp)
    }
    else{
      let data = await this.loadRestApi(this.main.base+'/wp-json/wp/v2/pages/',content.dataset.id,content.dataset.template)
      document.querySelector('#content').insertAdjacentHTML('afterbegin',data.csskfields.main)
    }
    
  
    this.el = document.querySelector('main')
    

    this.DOM = {
      el:this.el
    }

    gsap.set(this.DOM.el, {opacity: 0})

    await this.loadImages()
    await this.loadVideos()
    


    await this.createComps()
    await this.createIos()
    

    await this.getReady()
  }
  iOpage(animobj){
    if (animobj.el.classList.contains('iO-scrollservices')) {
			animobj.class = new Scrollservices(animobj, this.main)
		}
    return animobj
  }

  
  
  async createComps(){

    if (this.DOM.el.querySelector('.servicios_main')) {
			this.components.servicios = new Servicios(
				this.DOM.el.querySelector('.servicios_main'),
				this.main.device
			)
		}
    await super.createComps()
   

  }


  async animIntro(val){
    gsap.to(this.DOM.el, { opacity: 1, duration: .6, ease: 'Power2: inOut',
      onComplete: ()=>{
        if(document.documentElement.dataset.hasOwnProperty('anchor') && document.documentElement.dataset.anchor != ''){
          
          this.main.events.scrolltoanchor.detail.id = document.documentElement.dataset.anchor
          this.main.events.scrolltoanchor.detail.duration = 2;
          this.main.events.scrolltoanchor.detail.offset = 0;
          document.dispatchEvent(this.main.events.scrolltoanchor);
    
          document.documentElement.dataset.anchor = ''
        }
      }
    })
    
    return val
   
  }

  async animOut(){
    await gsap.to(this.DOM.el, { opacity: 0, duration: .6, ease: 'Power2: inOut'})
    
  }

}




export default Home