import Page from '/js🧠🧠🧠/page👁️/pagemain.js'

//COMPS
import Intro from './0Intro'


class Home extends Page {
	constructor(main) {
		super(main)

		this.active = 0
    this.cat = 0;
	}

	async create(content, main, temp = undefined) {
		super.create(content, main)
		if (temp != undefined) {
			document.querySelector('#content').insertAdjacentHTML('afterbegin', temp)
		} else {
			
			let data = await this.loadRestApi(
				this.main.base +  '/wp-json/wp/v2/pages/',
				content.dataset.id,
				content.dataset.template
			)
			document
				.querySelector('#content')
				.insertAdjacentHTML('afterbegin', data.csskfields.main)
		}
		this.el = document.querySelector('main')

		this.DOM = {
			el: this.el,
      
		}

    gsap.set(this.DOM.el , {opacity:0})

		await this.loadImages()
		await this.loadVideos()

		await this.createComps()
		await this.createIos()

		await this.getReady()

	}
	iOpage(animobj) {
		return animobj
	}

 


	async createComps() {
		await super.createComps()
		if (this.DOM.el.querySelector('.proyectos_intro')) {
			this.components.intro = new Intro(
				this.DOM.el.querySelector('.proyectos_intro'),
				this.main.touch,
				this.main.device
			)
		}
	}

	async animIntro(val) {
    await gsap.to(this.DOM.el , {opacity:1, duration: .9, ease: "inOut"})
	
		return val
	}

	async animOut() {
    await gsap.to(this.DOM.el , {opacity:0, duration: .6, ease: "inOut"})
  }

  scrolltotop() {
    this.main.events.scrollto.detail.id = "blogmain";
    this.main.events.scrollto.detail.duration = 1;
    this.main.events.scrollto.detail.offset = -100;
    document.dispatchEvent(this.main.events.scrollto);
  }
 
}

export default Home

