
import { timeout } from '/js🧠🧠🧠/defaults/math🧮.js'
import './index.scss'



class Loader {
  constructor (main,temp,device) {
    
    this.main = main
    this.counter = 0
    this.index = 0
    this.temp = {init:temp,pop:temp}
    this.device = device
    
  }
  async create(){
    document.querySelector('body').insertAdjacentHTML('afterbegin',this.temp.init)
    
    this.DOM = {
      el: document.documentElement.querySelector('.loader'),
      
    }

    this.createAnim()
  }
  
  createAnim(){
    
   
  }
  

  async hide(){

  }
  async show(){
  }
  async start () {

  }

  async showPop(){

    if(this.device > 1){
      
    }
  }

  async hidePop(){
    if(this.device > 1){
    }
  }

  async hideIntro(template = ''){


    this.DOM.el.remove()
    
   
  }

}

export default Loader
