// import Write from '/ios⛓️⛓️⛓️/Write'
import autoVideo from '/ios⛓️⛓️⛓️/autoVideo'
import lazyVideo from '/ios⛓️⛓️⛓️/lazyVideo'
import lazyImg from '/ios⛓️⛓️⛓️/lazyImg'
import lightNav from '/ios⛓️⛓️⛓️/lightnav.js'
import Stext from '/ios⛓️⛓️⛓️/Stext'

import animfooter from '/ios⛓️⛓️⛓️/animfooter.js'


export function buildThresholdList(numSteps) {
  var thresholds = []

  for (var i=1.0; i<=numSteps; i++) {
    var ratio = i/numSteps
    thresholds.push(ratio)
  }

  thresholds.push(0)
  return thresholds
}
//* función que se lanza en el callback de un io, solo se lanza si el IO tiene una clase
export function checkIo(pos,entry){
  
  const io = this.iosmap.get(pos)
  let check = io.class.check(entry)
  
  if(!io.class.isupdate){
    return false
  }

  // console.log(io)

  if(check == true){

    if(io.class.isupdate==1){

      
      if(this.iosupdaters.has(pos)==false){
        this.iosupdaters.set(pos,io)
      }
     
    }
    else if(io.class.isupdate==2){
    
      if(this.updaters.has(pos)==false){
        this.updaters.set(pos,io)

      }
    }
  }
  else{
    if(io.class.isupdate==1){
      if(this.iosupdaters.has(pos)==true){
        this.iosupdaters.delete(pos)
      }
    }
    else if(io.class.isupdate==2){
      if(this.updaters.has(pos)==true){
        this.updaters.delete(pos)
      }
    }


  }
  
  
  
}


//* Se lanza en start ( que debería ir después del page.show ) y lo que hace es hacer los observers
export function callIos(){
    
    this.callback = (entries,observer) =>{
      
      entries.forEach(entry=>{
        //Si la vista no está en visible, si el elemento no tiene pos o si tiene el dataset.no ( que lo endiña el delay )
        if(entry.target.dataset.no ||!entry.target.dataset.io || this.isVisible == 0){
          return false
        }
        
        const pos = parseInt(entry.target.dataset.io)
        // console.log(pos)
        // console.log(this.iosmap)
        if(this.iosmap.has(pos)){
          const io = this.iosmap.get(pos)
          if(io.class){
            this.checkIo(pos,entry)
          }
          else{
            
            if(entry.isIntersecting){
              this.inViewAddClass(entry)
            }
            // else{
              // entry.target.parentNode.classList.remove('inview')
               // entry.target.parentNode.classList.remove('okF')
            // }


          }
        }


      })
    }
    
    let root = null
    let opts = {
      root:root,
      threshold:[0,1]
    }
    

    this.observer = new IntersectionObserver(this.callback,opts)

    // this.ios = this.DOM.el.querySelectorAll('.iO')
    
    if(this.ios){
      for(let [i,a] of this.iosmap.entries()){
        
        this.observer.observe(a.el)

      }
    }

    

}


//* Hace la query de los ios, y lanza la fn iO, para seleccionar el tipo de iO
export function createIos () {
    this.DOM.ios = this.DOM.el.querySelectorAll('.iO')
    if(this.DOM.ios){
      let animobj = ''
      for(let[index,anim] of this.DOM.ios.entries()){
        animobj = this.iO(index,anim)


        this.iosmap.set(index,animobj)
      }
    }
  }
  
  //* Para las cargas de Ajax que genera nuevos elementos, buscar los Ios nuevos y elimina los que ya no están
  
  export async function newIos(fromel = null){

    let newios = null
    if(fromel == null){
      newios = document.body.querySelectorAll('.iO')
  
    }
    else{
      newios = fromel.querySelectorAll('.iO')
    }

    
  
    if(newios.length == 0){
      return false
    }
  
    newios = Array.prototype.slice.call(newios)

    let oldios = Array.prototype.slice.call(this.DOM.ios)

    for(let [i,a] of this.DOM.ios.entries()){
      let foundio = newios.find(element => element === a)

      if(foundio==undefined){

        
        let pos = a.dataset.io
        if(this.iosmap.get(pos)){
          let io = this.iosmap.get(pos)
          if(io.class){
            if(io.class.isupdate==1){
              if(this.iosupdaters.has(pos)==true){
                this.iosupdaters.delete(pos)
              }
            }
            else if(io.class.isupdate==2){
              if(this.updaters.has(pos)==true){
                this.updaters.delete(pos)
              }
            }
          }
          delete this.iosmap.class
          this.iosmap.unset(pos)
        }
        this.observer.unobserve(a)



      }

    }

    // HASTA AQUI
    this.ios = this.ios.filter(x => x !== undefined)
    // //Se borran los antiguos que ya no existen y se limpia el array   

    // //se buscan los nuevos
    for(let [i,a] of newios.entries()){

      let foundio = oldios.find(element => element === a)
      
      if(foundio==undefined){
        
        let newindex = i + this.DOM.ios.length
        const animobj = this.iO(newindex,a)


        this.iosmap.set(newindex,animobj)
        let iolast = this.iosmap.get(newindex)

        if(iolast.class){
          iolast.class.onResize(this.scroll.current)
        
        }
        this.observer.observe(a)
        
      }
    }

    this.DOM.ios = document.body.querySelectorAll('.iO')
    
  }

export  function iOpage(animobj){

    return animobj

}
//* Búsqueda de elementos Ios, lanza ioPage para buscar los específicos por page
export  function iO(index,anim){
    
    if(anim.dataset.io){
      return false
    }
      anim.dataset.io = index
    let animobj = {
      el: anim,
      pos: index,
      active: false
    }

    if(anim.classList.contains('iO-lazyVideo')){
      animobj.class = new lazyVideo(animobj,this.main.isTouch)
       
    }
    else if(anim.classList.contains('iO-lazyImg')){
      animobj.class = new lazyImg(animobj,this.main.device,this.main.isTouch)
      
    }
    else if(anim.classList.contains('iO-autoVideo')){
      animobj.class = new autoVideo(animobj,this.main.device,this.main.isTouch)
      
    }
    else if(anim.classList.contains('iO-lightNav')){
      animobj.class = new lightNav(animobj,this.main.device,this.main.isTouch)
      
    }
    else if(anim.classList.contains('iO-Stext')){
      animobj.class = new Stext(animobj,this.main.device,this.main.isTouch)
      
    }
    else if(anim.classList.contains('iO-animfooter')){
      animobj.class = new animfooter(animobj,this.main.device,this.main.isTouch)
      
    }
    else{

      if(anim.classList.contains('iO-std')){

        this.main.events.anim.detail.state = 0
        this.main.events.anim.detail.el = anim.parentNode
        document.dispatchEvent(this.main.events.anim)
      }

      animobj = this.iOpage(animobj)
    }

    if(animobj.class){
      if(animobj.class.prior==undefined){
        animobj.class.prior = 10
      }
      
    }
    return animobj

}

//* Mete clase y deja de observar ( se usa cuando no tiene ningún tipo )
export function inViewAddClass(entry){
    // if(entry.intersectionRatio > 0.6){
      entry.target.parentNode.classList.add('inview')
      
      if(entry.target.classList.contains('iO-std')){

        this.main.events.anim.detail.state = 1
        this.main.events.anim.detail.el = entry.target.parentNode
        document.dispatchEvent(this.main.events.anim)
        
        if(entry.target.parentNode.dataset.bucle){
          entry.target.parentNode.dataset.bucle = -1
          return false
          
        }
        
      }

      this.observer.unobserve(entry.target)
    // }
}


 //* Para mostrar los ios,lanza el show por si en el create se tiene que poner una animación de los ios a 0
 //* y se respetan delays y demás
export  function showIos(){
  for(let [i,a] of this.iosmap.entries()){
    a.el.style.visibility = 'visible'
   

  }

}
