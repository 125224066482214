//import SplitType from 'split-type'

export default class {
	constructor(obj, device, touch) {
		this.el = obj.el
    this.parent = obj.el.parentElement
		this.pos = obj.pos
		this.device = device
		this.touch = touch

		this.DOM = {
			el: obj.el,
			logoL: this.parent.querySelector('.logo-l'),
			logoR: this.parent.querySelector('.logo-r'),
      images: this.parent.querySelector('.images'), 
      image1: this.parent.querySelector('.image-1'),
      image2: this.parent.querySelector('.image-2'),
      image3: this.parent.querySelector('.image-3'),
      image4: this.parent.querySelector('.image-4'),
      image5: this.parent.querySelector('.image-5'),
      image6: this.parent.querySelector('.image-6'),
      image7: this.parent.querySelector('.image-7'),

      text: this.parent.querySelector('.text'),

      text1: this.parent.querySelector('.text_tr .medium'),
      text2: this.parent.querySelector('.text_tr .italic'),
      text3: this.parent.querySelector('.text_tr .thin'),
		}

    
		this.active = 0
    this.isupdate = 1

    this.actProj = 0

    // gsap.set(this.DOM.text2, {opacity:0})

		

    this.h = window.innerHeight
    
    this.animstick = {
      active:0,
      current:0,
      limit:0,
      start:0,
      prog:0,
      total:0,
    }


    this.create()
	}

	async create() {

		this.anim = gsap.timeline({paused:true})
		.fromTo(this.DOM.logoL,{scale:1, left:3.76+'vw'}, {scale: 1.96, left: 0, duration: 1}, 0)
		.fromTo(this.DOM.logoR,{scale:1, left:40.05+"vw"}, {scale: 1.96, left: 45.2+'vw', duration: 1}, 0)
    .fromTo(this.DOM.images,{opacity: 0, filter: 'blur(2px)'}, {opacity: 1, filter: 'blur(0)', duration: .7}, 1)

    .fromTo(this.DOM.logoL,{translateX:0}, {translateX: -100+'%', duration: 2}, 1.1)
		.fromTo(this.DOM.logoR, {translateX:0},{translateX: 100+'%', duration: 2}, 1.1)
    .fromTo(this.DOM.images,{left:26.62+'vw'}, {left:0, duration: 2,onComplete:()=>{
      this.DOM.images.style.overflow = "visible"
    }, onReverseComplete: ()=>{
      this.DOM.images.style.overflow = "hidden"
    }}, 1.1)

    .fromTo(this.DOM.image1,{translateY:0}, {translateY: -12+'rem', duration: 5}, 3.1)
    .fromTo(this.DOM.image2,{translateY:0}, {translateY: -44+'rem', duration: 5}, 3.1)
    .fromTo(this.DOM.image3,{translateY:0}, {translateY: -62+'rem', duration: 5}, 3.1)
    .fromTo(this.DOM.image4,{translateY:0}, {translateY: -36+'rem', duration: 5}, 3.1)
    .fromTo(this.DOM.image5,{translateY:0}, {translateY: -52+'rem', duration: 5}, 3.1)
    .fromTo(this.DOM.image6,{translateY:0}, {translateY: -38+'rem', duration: 5}, 3.1)
    .fromTo(this.DOM.image7,{translateY:0}, {translateY: -68+'rem', duration: 5}, 3.1)

    .fromTo(this.DOM.text,{translateY:50+'%'}, {translateY: -20+'%', duration: 4}, 3.6)
    .fromTo(this.DOM.text,{opacity:0}, {opacity: 1, duration: 2.2}, 3.6)

    .fromTo(this.DOM.text1,{opacity:1}, {opacity: 0, duration: .9}, 4.6)
    .fromTo(this.DOM.text2,{opacity:0}, {opacity: 1, duration: .9}, '>')
    .fromTo(this.DOM.text2,{opacity:1}, {opacity: 0, immediateRender:false, duration: .9}, '>')
    .fromTo(this.DOM.text3,{opacity:0}, {opacity: 1, duration: .9}, '>')
  }


	check(entry, pos) {
		let vis = false

		if (entry.isIntersecting == true) {
      vis = true
			this.start()
		} else {
      vis = false
			this.stop()
		}
		return vis
	}

	start(){
    this.active = 1
  }
  
  stop(){
    this.active = 0
  }


  update(pos){

    this.animstick.current = pos - this.animstick.start
    
    //CLAMP
    this.animstick.current = clamp(0, this.animstick.limit, this.animstick.current)
    this.animstick.prog = (this.animstick.current  / this.animstick.limit).toFixed(4)


    this.anim.progress(this.animstick.prog)

  }

  onResize(){
    this.w = window.innerWidth
    this.h = window.innerHeight


    //Start es para coger la posición inicial
    // El limit siempre será el tamaño de la pantalla + el tamaño del IO ( es todo el espacio que se va a ver)
    // El ratio lo hago para calcular cuando termina la primera parte ( hasta que está completamente dentro de la pantalla) y la segunda ( el resto )


    this.animstick.start = parseInt((this.DOM.el.getBoundingClientRect().y + window.scrollY ).toFixed(0))
    this.animstick.limit = parseInt((this.DOM.el.clientHeight - this.h).toFixed(0))
    // this.animstick.ratio = (this.DOM.el.clientHeight / this.h).toFixed(4)
    
    //* El kill y el create solo si el anim está hecho en absoluto y no con porcentaje
    //* Importante que todos los elementos que vayas a matar tengan un fromTo
    if(this.anim){
      this.anim.kill()
      this.create()
      this.update(window.scrollY)
    }


  }
}