
export default class {
  constructor (el,touch, device) {


    this.DOM = {
      el:el,
      cats: el.querySelectorAll(".cnt_top .cat"),

			projCnt: el.querySelector('.cnt_main'),
			projHold: el.querySelector('.cnt_main .hold'),
			projs: el.querySelectorAll('.cnt_main .hold .proj'),
    }
    this.device = device
		this.touch = touch
    this.active = 0
    this.create()

  }

  async create(){
    
  
  }
  
  async start(){
  }
  initEvents() {

		if(this.DOM.cats){
			for(let [i, p] of this.DOM.cats.entries()){
				p.onclick = async (e) => {
					e.preventDefault()

					this.DOM.cats[this.active].classList.remove('act')
					this.active = i
					this.DOM.cats[this.active].classList.add('act')

					let tipo = Number(p.dataset.tipo)
					this.DOM.projCnt.style.height = this.DOM.projHold.offsetHeight+'px'
					this.DOM.projHold.classList.add('loading')
					await gsap.to(this.DOM.projHold, {opacity:0, duration: .6, ease:"power2.inOut"})

					for(let proj of this.DOM.projs){
						let array = proj.dataset.tipo.split(',')
						let numberArray = array.map(Number);
						proj.classList.add('inview')
						if(tipo === 0){
							proj.style.display = 'block'
						}
						else if(numberArray.includes(tipo)){
							proj.style.display = 'block'
						}else{
							proj.style.display = 'none'
						}
					}
					await gsap.to(this.DOM.projCnt, {height:this.DOM.projHold.offsetHeight, duration: .45, ease:"power2.inOut",
						onComplete: () => {
							this.DOM.projCnt.style.height = 'auto'
						}
					})
					gsap.to(this.DOM.projHold, {opacity:1, duration: .6, ease:"power2.inOut", 
						onComplete: () => {
							this.DOM.projHold.classList.remove('loading')
						}
					})
					
				}
			}
		}


		if(this.DOM.el.querySelector('.proj') && !this.touch){
			
			for(let [i,p] of this.DOM.el.querySelectorAll('.proj').entries()){
				
				// let xTo = gsap.quickTo(p.querySelector('.proj_image .image img'), "xPercent"),
  			// 		yTo = gsap.quickTo(p.querySelector('.proj_image .image img'), "yPercent");
				p.querySelector('.proj_image').onmousemove = (e)=>{
					let rect = p.querySelector('.proj_image').getBoundingClientRect()
					let width = e.currentTarget.clientWidth 
					let height = e.currentTarget.clientHeight

					let x = ((width / 2) - (e.clientX - rect.left)) * -1
					let y = ((height / 2) - (e.clientY - rect.top)) * -1

					x = (x / width) * 20
					y = (y / height) * 20
					
					gsap.to(p.querySelector('.proj_image .image img'), {xPercent:x, yPercent:y, duration: .6, ease:"power2"})
					
					// xTo(x);
					// yTo(y);
				}

				p.querySelector('.proj_image').onmouseenter = (e)=>{
					gsap.to(p.querySelector('.proj_image .image img'), {scale: 1.2, duration: .3, ease:"power2.inOut"})
				}
				p.querySelector('.proj_image').onmouseleave = (e)=>{
					gsap.to(p.querySelector('.proj_image .image img'), {scale: 1.3, duration: .3, ease:"power2.inOut"})
					gsap.to(p.querySelector('.proj_image .image img'), {xPercent:0, yPercent:0, duration: .6, ease:"power2"})
				}
			}
		}

    
  }

  removeEvents(){
    
  }

  onResize(){
  }
}
